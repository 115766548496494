import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import { HeroViewData } from '@/components/HeroBanner/heroBannerData.model'
import USBButton from '@usb-shield/react-button'
import styles from '@/components/HeroBanner/DisplayViews/styles/fullPageBanner.module.scss'
import { useRouter } from 'next/router'

const twelveColsOption = {
  span: null,
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const FullPageBanner = ({
  title,
  mainHead,
  shortDescription,
  image,
  cta1Text,
  cta1Link,
  cta2Text,
  cta2Link,
}: HeroViewData) => {
  let imageSrc = process.env.CONTENT_DOMAIN + image

  const router = useRouter()
  
  let bannerStyle: string = styles.banner;
  let mainHeadStyle: string = styles.h1AsH2;
  let containerStyle: string = styles.container;
  let mediaStyle: string = styles.media;
  let backImgStyle: string = styles.backImg;
  
  if (router.pathname === '/alt-all-docs') {
    bannerStyle = styles.bannerAltAllDocs;
    mainHeadStyle = styles.h1Elavon;
    mediaStyle = styles.mediaAltAllDocs;
    backImgStyle = styles.backImgAltAllDocs;
    containerStyle = styles.containerAltAllDocs;
  }
  
  if (!shortDescription) {
    shortDescription = ''
  }

  return (
    <>
      <div id="FullPageBanner" className={containerStyle}>
        {/* Media */}
        <div className={mediaStyle}>
          <img
            className={backImgStyle}
            src={imageSrc}
            alt=""
          />
        </div>

        {/* Banner */}
        <USBGrid
          gridGap="normal"
          alignItems="start"
          columnCount="16"
          justifyContent="stretch"
          display="grid"
          padding="normal"
          addClasses={styles.bannerGrid}
        >
          <USBColumn
            addClasses={styles.contentBlock}
            layoutOpts={twelveColsOption}
          >
            <div className={bannerStyle}>
              <div className={styles.content}>
                {
                  title && process.env.SITE_BRAND !== 'elavon' 
                    ? (<h1>{title}</h1>) 
                    : null
                }

                {
                  (!title && process.env.SITE_BRAND === 'elavon')
                    ? (<h1 className={mainHeadStyle}>{mainHead}</h1>)
                    : (<h2>{mainHead}</h2>)
                }
                
                <p
                  data-testid="shortDesc"
                  dangerouslySetInnerHTML={{ __html: shortDescription }}
                ></p>

                {((cta1Link && cta1Link !== '') ||
                  (cta2Link && cta2Link !== '')) && (
                  <div className={styles.buttons}>
                    {cta1Link && cta1Link !== '' && (
                      <USBButton
                        variant="loud"
                        size="default"
                        spacing={{ margin: 8 }}
                        addClasses={styles.btn1}
                        dataTestId="heroBannerBtn1"
                        handleClick={() => {
                          window.open(cta1Link, '_self')
                        }}
                      >
                        {' '}
                        {cta1Text}
                      </USBButton>
                    )}

                    {cta2Link && cta2Link !== '' && (
                      <USBButton
                        variant="secondary"
                        size="default"
                        spacing={{ margin: 8 }}
                        addClasses={styles.btn2}
                        dataTestId="heroBannerBtn2"
                        handleClick={() => {
                          window.open(cta2Link, '_self')
                        }}
                      >
                        {' '}
                        {cta2Text}
                      </USBButton>
                    )}
                  </div>
                )}
              </div>
            </div>
          </USBColumn>
        </USBGrid>
      </div>
    </>
  )
}

export default FullPageBanner
